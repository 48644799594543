body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .iso-card-header {
  background-color: #1c203e;
  color: #fff;
}

.iso-card-content {
  background-color: #11163a;
  color: #fff;
} */

.iso-card {
  margin-bottom: 10px;
}

.profileBtn {
  cursor: pointer;
}

.smaller-text {
  font-size: 12px;
}

.iso-right {
  float: right;
}

.iso-small {
  font-size: 12px;
}

.iso-list-item {
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
}

.iso-error {
  font-size: 12px;
  color: red;
}

a.iso-a,
a {
  text-decoration: none;
  color: inherit;
}

.iso-notif-card {
  width: 350px;
  margin-bottom: 10px;
}
/* 
.iso-notif-card-read {
  
} */

.iso-notif-card-unread {
  border-bottom: 4px solid #007ad2;
}

.iso-list-item {
  border-top: 1px solid lightgrey;
}

.iso-text-field {
  margin-bottom: 10px !important;
}

.iso-select {
  margin-bottom: 10px !important;
}

.iso-search > div > fieldset {
  border-color: #fff !important;
}

.iso-search > div > input {
  color: #fff !important;
}

.capitalize {
  text-transform: capitalize;
}

.iso-pdf-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  background-color: lightgray;
  min-height: 100vh;
}

.iso-pdf-footer {
  display: flex;
  justify-content: space-between;
}

.iso-title-link {
  cursor: pointer;
}

.iso-title-link:hover {
  color: #ff9d03;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.sappi-tab {
  min-width: 100px;
}

.iso-version {
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}

.back-link {
  margin: 10px 0;
}

.filter-select {
  width: 250px;
}

/* Algolia */

ul.ais-Hits-list {
  list-style-type: none;
  box-sizing: inherit;
  padding-inline-start: 0;
}

h1 {
  font-size: 20px;
  font-weight: 400;
}

/* Start of Risk Matrix Styling */

#cats,
#likes {
  border: 1px solid #a0a0a0;
}

.row {
  display: flex;
}

.level,
.word-model,
.likelihood {
  width: 150px;
  align-items: center;
  padding: 20px;
  text-align: center;
  font-size: 12px;
  /* background-color: #efefef; */
  border-radius: 4px;
  border: 1px solid #efefef;
  margin: 1px;
}

.risk-matrix button {
  height: 100px;
  width: 100px;
}

.risk-matrix .high {
  background-color: red;
}
.risk-matrix .medium {
  background-color: orange;
}
.risk-matrix .low {
  background-color: green;
}

button.low,
button.medium,
button.high {
  border: none;
  border-radius: 4px;
  margin: 0.5px;
  cursor: pointer;
}

/* End Risk Matrix Styling */

.signblock {
  cursor: pointer;
}

.signblock:hover {
  background-color: #ff9d03;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.css-r11z79-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  white-space: normal !important;
}

.rotate-text {
  margin-top: 200px;
  transform: rotate(270deg);
  float: left;
  font-weight: bold;
}
